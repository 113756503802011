import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    // getDataList (state, data) {
    //   state.data = data
    // }
  },
  actions: {
    getStock ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('stock/get', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getamount ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('stock/getamount', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
