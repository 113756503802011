import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    listWarehouse: [],
    count: 0
  },
  mutations: {
    SET_WAREHOUSE_LIST (state, item) {
      state.listWarehouse = item
    },
    SET_TOTAL_ITEM (state, item) {
      state.count = item
    }
  },
  actions: {
    getWareHouse ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('warehouse/getall')
          .then(response => {
            commit('SET_WAREHOUSE_LIST', response.data.items)
            commit('SET_TOTAL_ITEM', response.data.totalItem)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    AddWareHouse  ({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.post('warehouse/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    DeleteWareHouse  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('warehouse/remove', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editWareHouse  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('warehouse/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }
}
