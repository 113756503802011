import axios from '../http/axios/index'

const mutations = {
  UPDATE_WINDOW_WIDTH (state, width) {
    state.windowsWidth = width
  },
  SET_ACCESS (state, accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  }

}
export default mutations
