// import jwt from '../../http/requests/auth/jwt/index.js'
// import useJwt from '@/@core/auth/jwt/useJwt'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import axios from '../../http/axios'

// const accessToken = 'accessToken'
// const refreshToken = 'refreshToken'
// const localStorageKey = 'loggedIn'
// const tokenExpiryKey = 'tokenExpiry'

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_BEARER (state, Token) {
      axios.defaults.headers.common.Authorization = `Bearer ${Token}`
    }
  },
  actions: {
    loginJWT ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', payload)
          .then(response => {
            if (response.data.success) {
              commit('SET_BEARER', response.data.accessToken)
            }
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    refreshToken ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/refreshtoken', payload)
          .then(response => {
            commit('SET_BEARER', response.data.accessToken)
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
}
