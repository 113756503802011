import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    uploadCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('upload/customer', item)
          .then(response => {
            console.log('SUCCESS!!')
            resolve(response)
          })
          .catch(err => {
            console.log('FAIL!!')
            reject(err)
          })
      })
    },
    uploadEmployer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('upload/employer', item)
          .then(response => {
            console.log('SUCCESS!!')
            resolve(response)
          })
          .catch(err => {
            console.log('FAIL!!')
            reject(err)
          })
      })
    },
    uploadProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('upload/product', item, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            console.log('SUCCESS!!')
            resolve(response)
          })
          .catch(err => {
            console.log('FAIL!!')
            reject(err)
          })
      })
    }
  }
}
