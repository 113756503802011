
import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    data: [],
    total: 0
  },
  mutations: {
    getDataList (state, data) {
      state.data = data
    },
    getTotal (state, data) {
      state.total = data
    }
  },
  actions: {
    getEmployerList ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('employer/getall')
          .then(response => {
            if (response.data.success) {
              commit('getDataList', response.data.data)
              commit('getTotal', response.data.data.length)
            }
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    AddEmployer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('employer/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getEmployerCode ({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.post('employer/get', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    DeleteEmployer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('employer/remove', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editEmployer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('employer/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }
}
