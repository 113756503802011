import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import state from './state'
import getters from './gethers'
import mutations from './mutations'
import moduleAuth from './auth/moduleAuthActions'
import moduleCustomer from './customer/moduleCustomer'
import employer from './employer/employer'
import warehouse from './warehouse/warehose'
import moduleUpload from './upload/moduleUpload'
import production from './production/production'
import stock from './stock/stock'
import moduleOrder from './order/moduleOrder'
import tranfer from './tranfer/tranfer'
import dashboard from './dashboard/dashboard'
import report from './report/reportorder'
import setting from './setting/setting'
import promotion from './promotion/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth: moduleAuth,
    customer: moduleCustomer,
    employer,
    warehouse,
    upload: moduleUpload,
    production,
    stock,
    order: moduleOrder,
    tranfer,
    dashboard,
    report,
    setting,
    promotion

  },
  strict: process.env.DEV,
  state,
  getters,
  mutations

})
