import axios from '../../http/axios/index'

export default {
  namespaced: true,
  actions: {
    getPromotionAll ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('promotion/getAll')
          .then(response => {
            commit('getDataList', response.data.items)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    AddPromotion  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('promotion/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    DeletePromotion  ({ commit }, item) {
      console.log(item)
      return new Promise((resolve, reject) => {
        axios.post('promotion/remove', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editPromotion ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('promotion/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }
}
