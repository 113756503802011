// import axiosIns from '@/libs/axios'
import axios from '../../axios'

let isAlreadyFetchingAccessToken = false

axios.interceptors.request.use(config => {
  const token = localStorage.accessToken
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
},
error => Promise.reject(error))

axios.interceptors.response.use(res => res,
  async error => {
    const { config, response } = error
    const originalRequest = config

    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        refreshToken().then(r => {
          isAlreadyFetchingAccessToken = false
          setaccessToken(r.data.accessToken)
          setrefreshToken(r.data.refreshToken)
          originalRequest.headers.Authorization = `Bearer ${r.data.accessToken}`
          return axios(originalRequest)
        })
        return Promise.reject(error)
      }
    }
  })

function refreshToken () {
  return axios.post('/refreshtoken', { refreshToken: localStorage.refreshToken })
}

function setaccessToken (token) {
  localStorage.setItem('accessToken', token)
}

function setrefreshToken (token) {
  localStorage.setItem('refreshToken', token)
}

export default axios
