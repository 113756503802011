import axios from '../../http/axios/index'

export default {
  namespaced: true,
  state: {
    data: [],
    dataProductOrder: [],
    datas: []
  },
  mutations: {
    getDataList (state, data) {
      state.data = data
    },
    getDataProducOrderList (state, item) {
      const items = item.item
      const dataInputs = []
      items.forEach((data, index) => {
        const setIndex = {
          index,
          priceProduct: data.promotion.length === 0 ? parseFloat(data.product.price.$numberDecimal)
            // eslint-disable-next-line radix
            : (parseFloat(data.product.price.$numberDecimal)) - (((parseFloat(data.product.price.$numberDecimal)) / 100) * parseInt(data.promotion[0].value.$numberDecimal))
        }
        dataInputs.push({
          warehouse: item.warehouse,
          inputs: {
            amount: 0,
            warehouse: data.warehouseId,
            addQty: 0
          }
        })
        items[index] = { ...data, ...setIndex }
      })
      state.datas = dataInputs
      state.dataProductOrder = items
      console.log('qqqq', state.dataProductOrder)
    },
    setStock (state, data) {
      state.dataProductOrder[data.index].amount.$numberDecimal = data.amount.toString()
    }

  },
  actions: {
    getProductAll ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('product/getAll')
          .then(response => {
            commit('getDataList', response.data.items)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getStockWhs ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('warehouse/getwarehouse', item)
          .then(response => {
            commit('setStock', { index: item.index, amount: response.data.amount })
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getProductOrderAll ({ commit }, dataW) {
      return new Promise((resolve, reject) => {
        axios
          .get('product/getmore')
          .then(response => {
            commit('getDataProducOrderList', { item: response.data.items, warehouse: dataW })
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('product/get', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    AddProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('product/add', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    DeleteProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('product/remove', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editProduct  ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('product/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }
}
