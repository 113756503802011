import axios from '../../http/axios/index'

export default {
  namespaced: true,
  actions: {
    getDataSum ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('dashboard/totalcard', item)
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    getItemBestSellofMonth ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('dashboard/totalproduct', item)
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    getIncomeOfYear ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('dashboard/chartsincome', item)
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    getBestSellCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('dashboard/chartscustomer', item)
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            }
          }).catch(err => {
            reject(err)
          })
      })
    },
    getBestChannel ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('dashboard/chartspayment', item)
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            }
          }).catch(err => {
            reject(err)
          })
      })
    }

  }
}
