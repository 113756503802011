import axios from '../../http/axios/index'

export default {
  namespaced: true,
  actions: {
    getReportOrder ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getallorder', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getReportProduct ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getproductinorder', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getReportOrderWareCustomer ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('order/getcustomer', item)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getFilexlsx ({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('export/excel', item, { responseType: 'blob' })
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    }
  }

}
