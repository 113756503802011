export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/production/authen/login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/production/dashboard/dashboard.vue')
  },
  {
    path: '/product/list',
    name: 'product_list',
    component: () => import('@/views/production/product/product_list.vue')
  },
  {
    path: '/employee/list',
    name: 'employee_list',
    component: () => import('@/views/production/employee/employee_list.vue')
  },
  {
    path: '/customer/list',
    name: 'customer_list',
    component: () => import('@/views/production/customer/customer_list.vue')
  }, {
    path: '/store',
    name: 'store',
    component: () => import('@/views/production/store/store.vue')
  }, {
    path: '/product/tranfer/:code',
    name: 'product_tranfer',
    component: () => import('@/views/production/product/product_tranfer.vue')
  }, {
    path: '/product/tranfer/:code/:whs',
    name: 'product_tranfer',
    component: () => import('@/views/production/product/product_tranfer.vue')
  }, {
    path: '/product/add',
    name: 'product_add',
    component: () => import('@/views/production/product/product_add.vue')
  }, {
    path: '/product/add/:code/:status',
    name: 'product_add',
    component: () => import('@/views/production/product/product_add.vue')
  }, {
    path: '/product/add/:code/:status/:whs',
    name: 'product_add',
    component: () => import('@/views/production/product/product_add.vue')
  },
  {
    path: '/employee/add',
    name: 'employee_add',
    component: () => import('@/views/production/employee/employee_add.vue')
  },
  {
    path: '/employee/add/:code',
    name: 'employee_add',
    component: () => import('@/views/production/employee/employee_add.vue')
  },
  {
    path: '/customer/add/:status',
    name: 'customer_add',
    component: () => import('@/views/production/customer/customer_add.vue')
  },
  {
    path: '/sales/order',
    name: 'sales_order',
    component: () => import('@/views/production/sales/sales_order.vue')
  },
  {
    path: '/customer/:code/edit',
    name: 'customer_edit',
    component: () => import('@/views/production/customer/customer_add.vue')
  },
  {
    path: '/reportorder/:startdate/:enddate',
    name: 'reportorder_date',
    component: () => import('@/views/production/report/reportOrder.vue')
  },
  {
    path: '/reportorder',
    name: 'reportorder_all',
    component: () => import('@/views/production/report/reportOrder.vue')
  },
  {
    path: '/customer/order/:code/:start/:end',
    name: 'customer_order',
    component: () => import('@/views/production/customer/customer_order.vue')
  },
  {
    path: '/customer/order/:code',
    name: 'customer_order',
    component: () => import('@/views/production/customer/customer_order.vue')
  },
  {
    path: '/reportproduct/:startdate/:enddate',
    name: 'reportproductsell2',
    component: () => import('@/views/production/report/reportProductSell.vue')
  },
  {
    path: '/reportproduct',
    name: 'reportproductsell',
    component: () => import('@/views/production/report/reportProductSell.vue')
  },
  {
    path: '/agent/list',
    name: 'agent_list',
    component: () => import('@/views/production/agent/agent_list.vue')
  }, {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/production/setting/setting.vue')
  }, {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/production/promotion/promotion.vue')
  },
  {
    path: '/promotionAdd',
    name: 'promotionAdd',
    component: () => import('@/views/production/promotion/promotion_add.vue')
  },
  {
    path: '/orderlist',
    name: 'orderlist',
    component: () => import('@/views/production/order/order_list.vue')
  },
  {
    path: '/ordermanagement/:code',
    name: 'ordermanagement',
    component: () => import('@/views/production/order/order_management.vue')
  },
  {
    path: '/orderpayment/:code/:type',
    name: 'orderpayment',
    component: () => import('@/views/production/order/order_payment.vue')
  }

]
